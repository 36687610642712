@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  padding-left: 30px;
  padding-right: 30px;
  height: 10vh;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: x-large;
  font-weight: bold;
}

.logout-button {
  border-width: 1px;
  border-color: rgb(90, 90, 90);
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 240px;
  background-color: #2b3642;
  padding: 30px;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.menu-text {
  color: white;
  justify-content: flex-start;
  background-color: #2b3642;
  padding-left: 8px;
}

.sidebar-icon {
  color: white;
}

.menu-item {
  align-items: center;
}

.content-area {
  background-color: #ebebeb;
  width: 100%;
  padding: 26px;
}

.my-element {
  position: relative;
}

.my-element::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/modalBackground.jpg");
  opacity: 0.1;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 